<template>
  <div v-if="chartPaneOpen" :class="[isFullscreen ? 'chart-pane-fullscreen' : 'chart-pane-overlay']">
    <div class="flex justify-between items-center">
      <div class="flex items-center space-x-4 text-sm">
        <div class="cursor-pointer" @click="toggleLegends">
          <font-awesome-icon :icon="['fas', 'list']" />
        </div>
        <div class="cursor-pointer" @click="clearChartItems">
          <font-awesome-icon :icon="['fas', 'eraser']" />
        </div>
        <div v-if="!isFullscreen" class="cursor-pointer" @click="toggleFullscreen">
          <font-awesome-icon :icon="['fas', 'maximize']" />
        </div>
        <div v-if="isFullscreen" class="cursor-pointer" @click="toggleFullscreen">
          <font-awesome-icon :icon="['fas', 'minimize']" />
        </div>
      </div>
      <div class="flex items-center text-xs">
        <button @click="setChartType('line')" :class="chartButtonClass('line')" class="rounded-l-full">Line</button>
        <!-- <button @click="setChartType('bar')" :class="chartButtonClass('bar')">Bar</button> -->
        <button @click="setChartType('accumulated')" :class="chartButtonClass('accumulated')" class="rounded-r-full">누적Bar</button>
      </div>
      <div class="cursor-pointer text-lg" @click="closeChartPane">
        <font-awesome-icon :icon="['fas', 'square-xmark']" />
      </div>
    </div>
    <div v-if="chartData && chartData.length" class="chart-container">
      <component :is="chartComponent" :data="data" :options="chartOptions" :key="chartRenderKey"></component>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Bar, Line } from 'vue-chartjs';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend, LineElement, PointElement, ChartOptions, Title } from 'chart.js';

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, LineElement, PointElement, Title);

const props = defineProps<{
  chartPaneOpen: boolean;
  isFullscreen: boolean;
  chartData: any;
  chartType: string;
  dateLabels: string[];
  showLegend: boolean;
  selectedMetric: string;
  selectedPeriod: string;
}>();

const emit = defineEmits([
  'toggle-legends',
  'clear-chart-items',
  'toggle-fullscreen',
  'set-chart-type',
  'close-chart-pane'
]);

const toggleLegends = () => emit('toggle-legends');
const clearChartItems = () => emit('clear-chart-items');
const toggleFullscreen = () => emit('toggle-fullscreen');
const setChartType = (type: string) => emit('set-chart-type', type);
const closeChartPane = () => emit('close-chart-pane');

const chartButtonClass = (type: string) => [
  'w-36 p-2',
  props.chartType === type ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
].join(' ');

const chartComponent = computed(() => {
  if (props.chartType === 'line') return Line;
  return Bar;
});

const colors = [
  'rgba(255, 99, 132, 0.7)', 'rgba(54, 162, 235, 0.7)', 'rgba(75, 192, 192, 0.7)',
  'rgba(255, 206, 86, 0.7)', 'rgba(153, 102, 255, 0.7)', 'rgba(255, 159, 64, 0.7)',
  'rgba(255, 99, 71, 0.7)', 'rgba(75, 192, 75, 0.7)', 'rgba(135, 206, 250, 0.7)',
  'rgba(128, 0, 128, 0.7)', 'rgba(255, 140, 0, 0.7)', 'rgba(0, 128, 128, 0.7)',
  'rgba(255, 215, 0, 0.7)', 'rgba(0, 191, 255, 0.7)', 'rgba(218, 112, 214, 0.7)',
  'rgba(139, 69, 19, 0.7)', 'rgba(60, 179, 113, 0.7)', 'rgba(30, 144, 255, 0.7)',
  'rgba(233, 150, 122, 0.7)', 'rgba(100, 149, 237, 0.7)', 'rgba(255, 20, 147, 0.7)',
  'rgba(0, 250, 154, 0.7)', 'rgba(176, 224, 230, 0.7)', 'rgba(255, 160, 122, 0.7)',
  'rgba(32, 178, 170, 0.7)', 'rgba(240, 128, 128, 0.7)', 'rgba(0, 206, 209, 0.7)',
  'rgba(147, 112, 219, 0.7)', 'rgba(70, 130, 180, 0.7)', 'rgba(222, 184, 135, 0.7)'
];

const borderColors = [
  'rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)',
  'rgba(255, 206, 86, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)',
  'rgba(255, 99, 71, 1)', 'rgba(75, 192, 75, 1)', 'rgba(135, 206, 250, 1)',
  'rgba(128, 0, 128, 1)', 'rgba(255, 140, 0, 1)', 'rgba(0, 128, 128, 1)',
  'rgba(255, 215, 0, 1)', 'rgba(0, 191, 255, 1)', 'rgba(218, 112, 214, 1)',
  'rgba(139, 69, 19, 1)', 'rgba(60, 179, 113, 1)', 'rgba(30, 144, 255, 1)',
  'rgba(233, 150, 122, 1)', 'rgba(100, 149, 237, 1)', 'rgba(255, 20, 147, 1)',
  'rgba(0, 250, 154, 1)', 'rgba(176, 224, 230, 1)', 'rgba(255, 160, 122, 1)',
  'rgba(32, 178, 170, 1)', 'rgba(240, 128, 128, 1)', 'rgba(0, 206, 209, 1)',
  'rgba(147, 112, 219, 1)', 'rgba(70, 130, 180, 1)', 'rgba(222, 184, 135, 1)'
];

const data = computed(() => {
  if (!props.chartData) return { labels: [], datasets: [] };

  const labels = props.dateLabels;

  if (props.chartType === 'accumulated') {
    return {
      labels,
      datasets: props.chartData.map((item, index) => ({
        label: item.name,
        data: item.values,
        backgroundColor: colors[index % colors.length],
        borderColor: borderColors[index % borderColors.length],
        borderWidth: 1,
        stack: 'stacked',
      }))
    };
  }

  return {
    labels,
    datasets: props.chartData.map((item, index) => ({
      label: item.name,
      data: item.values,
      backgroundColor: colors[index % colors.length],
      borderColor: borderColors[index % borderColors.length],
      borderWidth: 1
    })),
  };
});

const options: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: function(value) {
          if (props.selectedMetric === 'conversion_rate' || props.selectedMetric === 'sales_amount_proportion') {
            return `${value}%`;
          }
          // Apply comma for thousands separator
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },
  },
  plugins: {
    legend: {
      display: props.showLegend,
      position: 'right',
      align: 'start',
      labels: {
        font: {
          size: 10
        },
      }
    },
    title: {
      display: true,
      position: 'bottom',
      text: `${translateMetric(props.selectedMetric)} (${translatePeriod(props.selectedPeriod)})`,
      font: {
        size: 12,
        weight: 'normal',
      },
      color: 'black',
      padding: {
        top: 10,
        bottom: 0
      }
    }
  },
  animation: {
    duration: 300,
  }
};

const chartOptions = ref(options);
const chartRenderKey = ref(0);

function translateMetric(metric) {
    const metricMap = {
        'view_count': '조회수',
        'item_count': '판매수량',
        'sales_amount': '판매금액',
        'sales_amount_per_item': '제품당판매금액',
        'conversion_rate': '구매전환율(%)',
        'sales_amount_per_view': '조회당판매금액',
        'sales_amount_proportion': '판매금액비중(%)'
    };
    return metricMap[metric] || metric;
}

function translatePeriod(period) {
    const periodMap = {
        'daily': '일간',
        'monthly': '월간',
        'quarterly': '분기',
        'yearly': '연간'
    };
    return periodMap[period] || period;
}

watch(
  () => [props.showLegend, props.chartType, props.selectedMetric, props.selectedPeriod],
  () => {
    chartOptions.value.plugins = chartOptions.value.plugins || {};
    chartOptions.value.plugins.legend = chartOptions.value.plugins.legend || { display: true };
    chartOptions.value.plugins.legend.display = props.showLegend;
    chartOptions.value.plugins.title = chartOptions.value.plugins.title || { display: true };
    chartOptions.value.plugins.title.text = `${translateMetric(props.selectedMetric)} (${translatePeriod(props.selectedPeriod)})`;

    // Force re-render by updating chartRenderKey
    chartRenderKey.value++;
  },
  { immediate: true }
);
</script>

<style scoped>
.chart-pane-overlay {
  position: absolute;
  top: 13rem;
  right: 0;
  width: 80%;
  height: calc(100% - 13rem);
  background-color: white;
  z-index: 50;
  padding: 1rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chart-pane-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  padding: 1rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chart-container {
  position: relative;
  height: 100%;
  padding-top: 10px;
}
</style>

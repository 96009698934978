import App from '~/components/App.vue'
import { createApp } from 'vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBars,
  faCircleCheck,
  faCircleExclamation,
  faFillDrip,
  faSquareXmark,
  faEraser,
  faList,
  faMaximize,
  faMinimize,
  faPaperclip,
  faWandMagicSparkles,
  faRotateBackward,
  faRotateForward,
  faTag
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBars,
  faCircleCheck,
  faCircleExclamation,
  faFillDrip,
  faSquareXmark,
  faEraser,
  faList,
  faMaximize,
  faMinimize,
  faPaperclip,
  faWandMagicSparkles,
  faRotateBackward,
  faRotateForward,
  faTag
);

// To see this message, add the following to the `<head>` section in your

// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount('#app')

<template>
  <div class="flex h-full relative">
    <!-- Sidebar -->
    <Sidebar :sidebarOpen="sidebarOpen" :startDay="startDay" :endDay="endDay" @load="handleLoadButton" />

    <!-- Main content -->
    <div class="transition-all duration-300 ease-in-out flex-1 h-full flex flex-col overflow-hidden p-0">
      <div v-if="dataLoaded" class="overflow-auto flex-1 flex flex-col">
        <!-- Buttons for Topic  -->
        <div class="flex justify-between items-center px-4 py-2">
          <div class="flex space-x-2 text-xs">
            <button @click="selectTopic('customer', null)"
              class="px-4 py-2 rounded-sm border-0 bg-gray-50 text-gray-400">회원</button>
            <button @click="selectTopic('sales', 'item')" :class="buttonClassTopic('sales', 'item')">상품</button>
            <button @click="selectTopic('sales', 'product')" :class="buttonClassTopic('sales', 'product')">제품</button>
            <button @click="selectTopic('orders', null)"
              class="px-4 py-2 rounded-sm border-0 bg-gray-50 text-gray-400">주문</button>
          </div>
          <div class="flex items-center space-x-4">
            <div class="text-xs cursor-pointer" @click="openProductPane"><font-awesome-icon class="mr-0.5"
                :icon="['fas', 'paperclip']" /> <span>제품/카테고리 관리</span></div>
            <div class="text-xs cursor-pointer text-gray-400"><font-awesome-icon class="mr-1"
                :icon="['fas', 'wand-magic-sparkles']" /><span>AI 데이터분석</span></div>
          </div>
        </div>
        <!-- Buttons for Metrics -->
        <div class="flex space-x-2 text-xs px-4 py-2">
          <div v-if="selectedTopic === 'customer'">
            <button :class="buttonClassMetric('traffic_source')" class="bg-gray-50 text-gray-400">유입경로</button>
            <button :class="buttonClassMetric('funnel')" class="bg-gray-50 text-gray-400">구매단계</button>
            <button :class="buttonClassMetric('retention')" class="bg-gray-50 text-gray-400">고객유지</button>
            <button :class="buttonClassMetric('retention')" class="bg-gray-50 text-gray-400">고객등급</button>
            <button :class="buttonClassMetric('search_keyword')" class="bg-gray-50 text-gray-400">검색키워드</button>
            <!-- <button :class="buttonClassMetric('customer_levels')" class="bg-gray-50 text-gray-400">회원등급</button> -->
          </div>
          <div v-else-if="selectedTopic === 'marketing'"></div>
          <div v-else-if="selectedTopic === 'sales'">
            <button @click="setMetric('view_count')" :class="buttonClassMetric('view_count')">조회수</button>
            <button @click="setMetric('item_count')" :class="buttonClassMetric('item_count')">판매수량</button>
            <button @click="setMetric('sales_amount')" :class="buttonClassMetric('sales_amount')">판매금액</button>
            <button @click="setMetric('sales_amount_per_view')"
              :class="buttonClassMetric('sales_amount_per_view')">조회당판매금액</button>
            <button @click="setMetric('sales_amount_per_item')"
              :class="buttonClassMetric('sales_amount_per_item')">개당판매금액</button>
            <button @click="setMetric('conversion_rate')"
              :class="buttonClassMetric('conversion_rate')">구매전환율(%)</button>
            <button @click="setMetric('sales_amount_proportion')"
              :class="buttonClassMetric('sales_amount_proportion')">판매금액비중(%)</button>
            <button :class="buttonClassMetric('sales_cost_ratio')" class="bg-gray-50 text-gray-400">원가율(%)</button>
            <button :class="buttonClassMetric('sales_cost_ratio')" class="bg-gray-50 text-gray-400">취소/반품률(%)</button>
          </div>
          <div v-else-if="selectedTopic === 'orders'">
            <button :class="buttonClassMetric('order_options')" class="bg-gray-50 text-gray-400">주문옵션</button>
            <button :class="buttonClassMetric('discount_and_rewards')" class="bg-gray-50 text-gray-400">할인/적립</button>
            <button :class="buttonClassMetric('payment_options')" class="bg-gray-50 text-gray-400">결제수단</button>
            <button :class="buttonClassMetric('order_time')" class="bg-gray-50 text-gray-400">주문시간대</button>
            <button :class="buttonClassMetric('order_region')" class="bg-gray-50 text-gray-400">주문지역</button>
          </div>
        </div>
        <!-- Tables  -->
        <div class="mt-2 flex items-center px-4">
          <input type="text" v-model="searchKeyword" placeholder="Search items..." :class="searchClass"
            :disabled="selectedMetric == 'sales_amount_proportion'">
          <button @click="toggleColorScale" class="toggle-color"
            :class="['ml-4 px-2 py-1 rounded-sm border-0', colorScaleEnabled[selectedMetric] ? 'bg-[#171717] text-white font-semibold' : '']">
            <font-awesome-icon :icon="['fas', 'fill-drip']" />
          </button>
        </div>
        <div class="mt-2 flex-1 overflow-auto">
          <div class="min-w-full overflow-x-auto h-full">
            <table class="min-w-full bg-white text-xs">
              <thead>
                <tr>
                  <th
                    class="sticky top-0 left-0 px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider z-10 cursor-pointer"
                    @click="sortByName">{{ groupBy === 'item' ? '상품명' : '제품명' }}</th>
                  <th v-for="date in dateRange" :key="date"
                    class="sticky top-0 px-4 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    @click="sortTable(date)">
                    {{ formatDate(date) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    class="sticky left-0 px-4 py-3 whitespace-nowrap border-b border-gray-200 bg-gray-600 text-white font-medium z-5">
                    합계</td>
                  <td v-for="date in dateRange" :key="date"
                    class="px-4 py-3 whitespace-nowrap border-b border-gray-200 bg-gray-600 text-white text-right font-medium">
                    {{ formatNumber(getFilteredTotalSum(date)) }}
                  </td>
                </tr>
                <tr v-for="(item, index) in filteredStatistics" :key="item.entity.id">
                  <td class="sticky left-0 px-4 py-3 whitespace-nowrap border-b border-gray-200 z-5 cursor-pointer"
                    :class="{ 'bg-black text-white': isSelected(item.entity), 'bg-gray-50': !isSelected(item.entity) }"
                    @click="(event) => toggleChartItem(item.entity, index, event)">
                    {{ item.entity.name }}
                  </td>
                  <td v-for="date in dateRange" :key="date" :class="getCellClass(item.entity, date)"
                    :style="getCellStyle(item, date)"
                    class="relative min-w-[90px] max-w-[90px] overflow-hidden text-ellipsis px-4 py-3 whitespace-nowrap break-keep border-b border-gray-200 text-right cursor-pointer relative"
                    @click="setMemo(item.entity, date)" :title="getMemo(item.entity, date)?.content || ''">
                    {{ formatNumber(getMetricValue(item.sales, date, selectedMetric)) }}
                    <div v-if="getMemo(item.entity, date)" class="mt-2 text-rose-500 font-medium">{{ getMemo(item.entity,
                      date)?.content }}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


    <!-- Chart Pane Overlay -->
    <ChartPaneOverlay :chartPaneOpen="chartPaneOpen" :isFullscreen="isFullscreen" :chartData="chartData"
      :chartType="chartType" :dateLabels="dateLabels" :showLegend="showLegend" :selectedMetric="selectedMetric"
      :selectedPeriod="selectedPeriod" @toggle-legends="toggleLegends" @clear-chart-items="clearChartItems"
      @toggle-fullscreen="toggleFullscreen" @set-chart-type="setChartType" @close-chart-pane="closeChartPane" />

    <ProductPaneOverlay :productPaneOpen="props.productPaneOpen" @close-product-pane="closeProductPane" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, StyleValue } from 'vue';
import Sidebar from './sub/Sidebar.vue';
import ChartPaneOverlay from './sub/ChartPaneOverlay.vue';
import ProductPaneOverlay from './sub/ProductPaneOverlay.vue';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';

const emit = defineEmits(['loading', 'toggle-chart-pane', 'open-product-pane', 'close-product-pane']);

interface BaseEntity {
  id: number;
  name: string;
  selected: boolean;
}

interface Item extends BaseEntity { }
interface Product extends BaseEntity { }
interface Customer extends BaseEntity { }
interface Order extends BaseEntity { }

interface Sales {
  [key: string]: {
    sales_amount?: number;
    item_count?: number;
    view_count?: number;
    order_count?: number;
  };
}

interface Statistic<T extends BaseEntity> {
  entity: T;
  sales: Sales;
}

interface Memo {
  id?: number;
  item_id: number;
  period: string;
  date: string;
  content: string;
}

const props = defineProps<{
  currentMallId: number;
  sidebarOpen: boolean;
  startDay: string;
  endDay: string;
  productPaneOpen: boolean;
}>();

const statistics = ref<Array<Statistic<Item | Product>>>([]);
const selectedTopic = ref<'customer' | 'marketing' | 'sales' | 'orders'>('sales');
const groupedBy = ref<'item' | 'product'>('item');
const error = ref<string | null>(null);
const loading = ref(false);
const dataLoaded = ref(false);
const startDate = ref('');
const endDate = ref('');
const startWeek = ref('');
const endWeek = ref('');
const startMonth = ref('');
const endMonth = ref('');
const startQuarter = ref('');
const endQuarter = ref('');
const startYear = ref('');
const endYear = ref('');
const selectedMetric = ref('sales_amount');
const dateRange = ref<string[]>([]);
const selectedPeriod = ref('daily');
const searchKeyword = ref('');
const debouncedSearchKeyword = ref('');
const memos = ref<Memo[]>([]);
const totals = ref<{ [key: string]: number }>({});
const colorScaleEnabled = ref<{ [metric: string]: boolean }>({});
const colorScaleCache = ref<{ [metric: string]: { [key: string]: { backgroundColor: string } } }>({});
const selectedItems = ref<Array<Item | Product>>([]);
const lastSelectedIndex = ref<number | null>(null);
const chartType = ref<string>('accumulated');
const chartPaneOpen = ref<boolean>(false);
// const productPaneOpen = ref<boolean>(false);;
const showLegend = ref<boolean>(true);
const isFullscreen = ref<boolean>(false);
const groupBy = ref<'item' | 'product' | 'customer' | 'marketing' | 'orders' | null>('item');
const memoMap = ref(new Map());

const generateUrl = () => {
  let url = '';
  const groupByValue = groupBy.value;

  switch (selectedPeriod.value) {
    case 'daily':
      if (!startDate.value || !endDate.value) {
        error.value = 'Start date and end date are required.';
        return;
      }
      url = `/sales?period=daily&start_date=${startDate.value}&end_date=${endDate.value}&group_by=${groupByValue}`;
      break;
    case 'weekly':
      if (!startWeek.value || !endWeek.value) {
        error.value = 'Start week and end week are required.';
        return;
      }
      const startWeekFormatted = startWeek.value.replace('-W', '');
      const endWeekFormatted = endWeek.value.replace('-W', '');
      url = `/sales?period=weekly&start_week=${startWeekFormatted}&end_week=${endWeekFormatted}&group_by=${groupByValue}`;
      break;
    case 'monthly':
      if (!startMonth.value || !endMonth.value) {
        error.value = 'Start month and end month are required.';
        return;
      }
      url = `/sales?period=monthly&start_month=${startMonth.value}&end_month=${endMonth.value}&group_by=${groupByValue}`;
      break;
    case 'quarterly':
      if (!startQuarter.value || !endQuarter.value) {
        error.value = 'Start quarter and end quarter are required.';
        return;
      }
      url = `/sales?period=quarterly&start_quarter=${startQuarter.value.replace('Q', '')}&end_quarter=${endQuarter.value.replace('Q', '')}&group_by=${groupByValue}`;
      break;
    case 'yearly':
      if (!startYear.value || !endYear.value) {
        error.value = 'Start year and end year are required.';
        return;
      }
      url = `/sales?period=yearly&start_year=${startYear.value}&end_year=${endYear.value}&group_by=${groupByValue}`;
      break;
  }

  return url;
};

const listStatistics = async () => {
  let url = generateUrl();

  if (!url) return;

  loading.value = true;
  error.value = null;

  try {
    emit('loading', true);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    // console.log('Fetched data:', data);

    // Process sales data
    if (selectedTopic.value === 'sales' && data.sales) {
      statistics.value = data.sales.map((stat) => {
        const entityData = stat.item || stat.product || {};
        return {
          entity: {
            id: entityData.id || null,
            name: entityData.name || 'Unknown',
            selected: false,
          },
          sales: stat.sales || {},
        };
      });

      // console.log('Processed statistics:', statistics.value);

      const newTotals = {};
      statistics.value.forEach((stat) => {
        Object.keys(stat.sales).forEach(date => {
          if (!newTotals[date]) {
            newTotals[date] = { sales_amount: 0 };
          }
          newTotals[date].sales_amount += stat.sales[date].sales_amount || 0;
        });
      });
      totals.value = newTotals;
      // console.log('Updated totals:', totals.value);
    } else {
      statistics.value = [];
      totals.value = {};
    }

    // Process memos data
    memos.value = data.memos.map((memo) => ({
      ...memo,
      item_id: memo.memoable_id,
      period: memo.period,
      date: memo.date,
      content: memo.content,
    }));

    const newMemoMap = new Map();
    memos.value.forEach(memo => {
      const key = `${memo.item_id}_${memo.date}`;
      newMemoMap.set(key, memo);
    });
    memoMap.value = newMemoMap;

    // console.log('Updated memoMap:', memoMap.value);

    updateDateRange();
    dataLoaded.value = true;
    colorScaleCache.value = {};
    colorScaleEnabled.value = {};
  } catch (err) {
    console.error('Error fetching statistics:', err.message);
    error.value = err.message;
  } finally {
    loading.value = false;
    emit('loading', false);
  }
};

const updateDateRange = () => {
  const range: string[] = [];
  if (selectedPeriod.value === 'daily') {
    let currentDate = dayjs(startDate.value);
    const end = dayjs(endDate.value);
    while (currentDate <= end) {
      range.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
  } else if (selectedPeriod.value === 'weekly') {
    // TODO: 임시로 설정해 뒀다. 나중에 다 통일해야 한다.
    if (statistics.value.length > 0) {
      // Get the first entry's sales object to extract the date keys
      const dateKeysSet = new Set<string>();
      statistics.value.forEach(entry => {
        Object.keys(entry.sales).forEach(dateKey => {
          dateKeysSet.add(dateKey);
        });
      });
      // Convert the set to an array and sort it
      range.push(...Array.from(dateKeysSet).sort());
      // console.log("Weekly Date Range:", range);  // Debugging log
    }
  } else if (selectedPeriod.value === 'monthly') {
    let currentDate = dayjs(`${startMonth.value}01`);
    const end = dayjs(`${endMonth.value}01`).endOf('month');
    while (currentDate <= end) {
      range.push(currentDate.format('YYYYMM'));
      currentDate = currentDate.add(1, 'month');
    }
  } else if (selectedPeriod.value === 'quarterly') {
    try {
      if (startQuarter.value && endQuarter.value) {
        let currentYear = parseInt(startQuarter.value.slice(0, 4));
        let currentQuarter = parseInt(startQuarter.value.slice(-1));
        const endYear = parseInt(endQuarter.value.slice(0, 4));
        const endQuarterValue = parseInt(endQuarter.value.slice(-1));

        if (isNaN(currentYear) || isNaN(currentQuarter) || isNaN(endYear) || isNaN(endQuarterValue)) {
          throw new Error('Invalid quarter format');
        }

        while (currentYear < endYear || (currentYear === endYear && currentQuarter <= endQuarterValue)) {
          range.push(`${currentYear}${currentQuarter}`);
          if (currentQuarter === 4) {
            currentQuarter = 1;
            currentYear++;
          } else {
            currentQuarter++;
          }
        }
      } else {
        throw new Error('Start Quarter or End Quarter is not defined');
      }
    } catch (error) {
      console.error('Error processing quarters:', error);
    }
  } else if (selectedPeriod.value === 'yearly') {
    let currentYear = parseInt(startYear.value);
    const endYearValue = parseInt(endYear.value);
    while (currentYear <= endYearValue) {
      range.push(`${currentYear}`);
      currentYear++;
    }
  }
  // console.log(range);
  dateRange.value = range;
};

const getMetricValue = (sales: Sales, date: string, metric: string): number => {
  if (!sales[date]) return 0;
  switch (metric) {
    case 'sales_amount_per_item':
      const totalSalesAmount = sales[date]?.sales_amount || 0;
      const totalItemCount = sales[date]?.item_count || 0;
      return totalItemCount ? totalSalesAmount / totalItemCount : 0;
    case 'sales_amount_per_view':
      const totalViewCount = sales[date]?.view_count || 0;
      return totalViewCount ? (sales[date]?.sales_amount || 0) / totalViewCount : 0;
    case 'conversion_rate':
      const totalViewCountCR = sales[date]?.view_count || 0;
      return totalViewCountCR ? (sales[date]?.order_count || 0) / totalViewCountCR * 100 : 0;
    case 'sales_amount_proportion':
      const filteredTotalSalesAmount = getFilteredTotalSalesAmount(date);
      return filteredTotalSalesAmount ? (sales[date]?.sales_amount || 0) / filteredTotalSalesAmount * 100 : 0;
    default:
      return sales[date]?.[metric] || 0;
  }
};

const getFilteredTotalSalesAmount = (date: string): number => {
  let total = 0;
  filteredStatistics.value.forEach((item) => {
    total += item.sales[date]?.sales_amount || 0;
  });
  return total;
};

const getFilteredTotalSum = (date: string, metric: string = selectedMetric.value): number => {
  let total = 0;
  if (metric === 'sales_amount_per_item') {
    let totalSalesAmount = 0;
    let totalItemCount = 0;
    filteredStatistics.value.forEach((item) => {
      totalSalesAmount += item.sales[date]?.sales_amount || 0;
      totalItemCount += item.sales[date]?.item_count || 0;
    });
    total = totalItemCount ? totalSalesAmount / totalItemCount : 0
  } else if (metric === 'sales_amount_per_view') {
    let totalSalesAmount = 0;
    let totalViewCount = 0;
    filteredStatistics.value.forEach((item) => {
      totalSalesAmount += item.sales[date]?.sales_amount || 0;
      totalViewCount += item.sales[date]?.view_count || 0;
    });
    total = totalViewCount ? totalSalesAmount / totalViewCount : 0;
  } else if (metric === 'conversion_rate') {
    let totalOrderCount = 0;
    let totalViewCount = 0;
    filteredStatistics.value.forEach((item) => {
      totalOrderCount += item.sales[date]?.order_count || 0;
      totalViewCount += item.sales[date]?.view_count || 0;
    });
    total = totalViewCount ? (totalOrderCount / totalViewCount) * 100 : 0;
  } else if (metric === 'sales_amount') {
    filteredStatistics.value.forEach((item) => {
      total += item.sales[date]?.sales_amount || 0;
    });
  } else if (metric === 'sales_amount_proportion') {
    const filteredTotalSalesAmount = getFilteredTotalSalesAmount(date);
    filteredStatistics.value.forEach((item) => {
      total += filteredTotalSalesAmount ? (item.sales[date]?.sales_amount || 0) / filteredTotalSalesAmount * 100 : 0;
    });
  } else {
    filteredStatistics.value.forEach((item) => {
      total += getMetricValue(item.sales, date, metric);
    });
  }
  return total;
};

const setMetric = async (metric: string) => {
  selectedMetric.value = metric;
  loading.value = true;
  emit('loading', true);
  await new Promise(resolve => setTimeout(resolve, 0));
  loading.value = false;
  emit('loading', false);
};

const selectTopic = async (topic: 'customer' | 'marketing' | 'sales' | 'orders', group: 'item' | 'product' | null) => {
  selectedTopic.value = topic;

  if (topic === 'sales') {
    groupBy.value = group;
  } else {
    groupBy.value = topic;
  }

  loading.value = true;
  emit('loading', true);
  clearChartItems();
  closeChartPane();
  await listStatistics();
  loading.value = false;
  emit('loading', false)
}

const formatDate = (date: string): string => {
  if (selectedPeriod.value === 'daily') {
    return dayjs(date).format('YYYY-MM-DD');
  } else if (selectedPeriod.value === 'weekly') {
    return date;
  } else if (selectedPeriod.value === 'monthly') {
    return date.slice(0, 6); // YYYYMM format
  } else if (selectedPeriod.value === 'quarterly') {
    const year = date.slice(0, 4);
    const quarter = date.slice(-1);
    return `${year}Q${quarter}`;
  } else if (selectedPeriod.value === 'yearly') {
    return date; // YYYY format
  }
  return date;
};

const formatNumber = (number: number): string => {
  let default_value = '0';

  if (['conversion_rate', 'sales_amount_per_item', 'sales_amount_per_view', 'sales_amount_proportion'].includes(selectedMetric.value)) {
    default_value = '';
  }

  if (selectedMetric.value === 'conversion_rate' || selectedMetric.value === 'sales_amount_proportion') {
    return number ? `${number.toFixed(1)}%` : default_value;
  }

  return number ? Math.round(number).toLocaleString() : default_value;
};

const getCellClass = (entity: Item | Product, date: string): string => {
  const memo = getMemo(entity, date);
  if (memo?.content) {
    return 'hasMemo'; // Memo style
  }
  if (selectedPeriod.value === 'daily') {
    const day = dayjs(date).day();
    if (day === 6) {
      return 'weekend-saturday'; // Temporary class for Saturday
    } else if (day === 0) {
      return 'weekend-sunday'; // Temporary class for Sunday
    }
  }
  return '';
};

const getCellStyle = (entity: Statistic<Item | Product>, date: string): StyleValue => {
  const memo = getMemo(entity.entity, date);
  if (memo?.content) {
    return {};
  }
  if (!colorScaleEnabled.value[selectedMetric.value]) {
    return {};
  }
  const key = `${entity.entity.id}_${date}`;
  const cachedStyle = colorScaleCache.value[selectedMetric.value]?.[key];
  return typeof cachedStyle === 'object' ? cachedStyle : {};
};

const toggleColorScale = async () => {
  loading.value = true;
  emit('loading', true);
  colorScaleEnabled.value[selectedMetric.value] = !colorScaleEnabled.value[selectedMetric.value];
  if (colorScaleEnabled.value[selectedMetric.value]) {
    await applyColorScale();
  }
  loading.value = false;
  emit('loading', false);
};

const applyColorScale = async () => {
  const values: { [key: string]: number } = {};
  dateRange.value.forEach(date => {
    statistics.value.forEach(entity => {
      const value = getMetricValue(entity.sales, date, selectedMetric.value);
      const key = `${entity.entity.id}_${date}`;
      values[key] = value;
    });
  });

  const allValues = Object.values(values);
  const max = selectedMetric.value === 'conversion_rate' || selectedMetric.value === 'sales_amount_proportion' ? 100 : Math.max(...allValues);
  const min = Math.min(...allValues);

  const newColorScaleCache: { [metric: string]: { [key: string]: { backgroundColor: string } } } = {};
  statistics.value.forEach(entity => {
    dateRange.value.forEach(date => {
      const key = `${entity.entity.id}_${date}`;
      const value = values[key];
      const intensity = (Math.min(value, max) - min) / (max - min) || 0;
      const color = `rgba(0, 255, 0, ${intensity})`;
      if (!newColorScaleCache[selectedMetric.value]) {
        newColorScaleCache[selectedMetric.value] = {};
      }
      newColorScaleCache[selectedMetric.value][key] = { backgroundColor: color };
    });
  });

  colorScaleCache.value = newColorScaleCache;
};

const sortTable = async (date: string) => {
  loading.value = true;
  emit('loading', true);
  await new Promise(resolve => setTimeout(resolve, 0));
  const sortedStatistics = [...statistics.value].sort((a, b) => {
    const aValue = getMetricValue(a.sales, date, selectedMetric.value);
    const bValue = getMetricValue(b.sales, date, selectedMetric.value);
    return bValue - aValue;
  });
  statistics.value = sortedStatistics;
  loading.value = false;
  emit('loading', false);
};

const sortByName = () => {
  const sortedStatistics = [...statistics.value].sort((a, b) => {
    if (a.entity.name < b.entity.name) return -1;
    if (a.entity.name > b.entity.name) return 1;
    return 0;
  });
  statistics.value = sortedStatistics;
};

const handleLoadButton = (params: any) => {
  selectedPeriod.value = params.sidebarSelectedPeriod;
  startDate.value = params.sidebarStartDate;
  endDate.value = params.sidebarEndDate;
  startWeek.value = params.sidebarStartWeek;
  endWeek.value = params.sidebarEndWeek;
  startMonth.value = params.sidebarStartMonth;
  endMonth.value = params.sidebarEndMonth;
  startQuarter.value = params.sidebarStartQuarter;
  endQuarter.value = params.sidebarEndQuarter;
  startYear.value = params.sidebarStartYear;
  endYear.value = params.sidebarEndYear;
  listStatistics();
};

const filteredStatistics = computed(() => {
  if (!debouncedSearchKeyword.value) {
    return statistics.value;
  }
  const keyword = debouncedSearchKeyword.value.toLowerCase();
  return statistics.value.filter(entity =>
    entity.entity.name.toLowerCase().includes(keyword)
  );
});

const debouncedUpdate = debounce((newKeyword: string) => {
  debouncedSearchKeyword.value = newKeyword;
}, 500);

watch(searchKeyword, (newKeyword: string, oldKeyword: string) => {
  debouncedUpdate(newKeyword);
});

const chartData = computed(() => {
  return selectedItems.value.map(entity => {
    const salesData = statistics.value.find(stat => stat.entity.id === entity.id)?.sales || {};
    const values = dateRange.value.map(date => getMetricValue(salesData, date, selectedMetric.value));
    return { name: entity.name, values };
  });
});

const dateLabels = computed(() => {
  return dateRange.value.map(date => formatDate(date));
});

const toggleChartItem = (entity: Item | Product, index: number, event: MouseEvent) => {
  if (event.shiftKey && lastSelectedIndex.value !== null) {
    const start = Math.min(lastSelectedIndex.value, index);
    const end = Math.max(lastSelectedIndex.value, index);
    for (let i = start; i <= end; i++) {
      const item = filteredStatistics.value[i].entity;
      if (!isSelected(item)) {
        selectedItems.value.push(item);
      }
    }
  } else {
    const itemIndex = selectedItems.value.findIndex(selectedItem => selectedItem.id === entity.id);
    if (itemIndex === -1) {
      selectedItems.value.push(entity);
    } else {
      selectedItems.value.splice(itemIndex, 1);
    }
  }
  lastSelectedIndex.value = index;
  chartPaneOpen.value = !!selectedItems.value.length;
  emit('toggle-chart-pane', chartPaneOpen.value);
};

const isSelected = (entity: Item | Product) => {
  return selectedItems.value.some(selectedEntity => selectedEntity.id === entity.id);
};

const clearChartItems = () => {
  selectedItems.value = [];
};

const setChartType = (type: string) => {
  chartType.value = type;
};

const closeChartPane = () => {
  chartPaneOpen.value = false;
};

const toggleLegends = () => {
  showLegend.value = !showLegend.value;
};

const toggleFullscreen = () => {
  isFullscreen.value = !isFullscreen.value;
};

const buttonClassTopic = (topic: string, group: string) => {
  if (topic === 'sales') {
    return ['px-4 py-2 rounded-sm border-0', selectedTopic.value === 'sales' && groupBy.value === group ? 'bg-[#171717] text-white font-semibold' : ''].join(' ');
  } else {
    return ['px-4 py-2 rounded-sm border-0', selectedTopic.value === topic ? 'bg-[#171717] text-white font-semibold' : ''].join(' ');
  }
}

const buttonClassMetric = (metric: string) => ['px-2 w-[120px] py-2 rounded-sm border-0', selectedMetric.value === metric ? 'bg-[#171717] text-white font-semibold' : ''].join(' ');

const searchClass = { 'p-2 border border-black rounded-sm w-[380px] max-w-lg text-xs': true, 'input-disabled': selectedMetric.value == 'sales_amount_proportion' };

const colorScaleButtonClass = ['ml-4 px-2 py-2 rounded-sm border-0', colorScaleEnabled.value[selectedMetric.value] ? 'bg-[#171717] text-white font-semibold' : ''].join(' ');

const chartButtonClass = (type: string) => ['w-36 p-2', chartType.value === type ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'].join(' ');

const getMemo = (item: Item, date: string): Memo | null => {
  const formattedDate = selectedPeriod.value === 'daily' ? dayjs(date).format('YYYYMMDD') : date;
  const key = `${item.id}_${formattedDate}`;
  const memo = memoMap.value.get(key);
  return memo || null;
};

const setMemo = async (item: Item, date: string) => {
  const formattedDate = selectedPeriod.value === 'daily' ? dayjs(date).format('YYYYMMDD') : date;
  const existingMemo = getMemo(item, formattedDate);
  const content = prompt('Enter memo:', existingMemo ? existingMemo.content : '');
  const memoData = {
    mall_id: props.currentMallId,
    memoable_type: groupBy.value === 'product' ? 'Product' : 'Item',
    memoable_id: item.id,
    period: selectedPeriod.value,
    date: formattedDate,
    content,
  };

  try {
    if (existingMemo) {
      if (content) {
        // Update memo
        const response = await fetch(`/memos/${existingMemo.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
          },
          body: JSON.stringify(memoData)
        });
        if (!response.ok) throw new Error('Failed to update memo');
        const updatedMemo = await response.json();
        // Update local memo with correct id
        const memoIndex = memos.value.findIndex(m => m.id === existingMemo.id);
        if (memoIndex >= 0) {
          memos.value[memoIndex] = updatedMemo;
        }
        memoMap.value.set(`${item.id}_${formattedDate}`, updatedMemo);
      } else {
        // Destroy memo
        const response = await fetch(`/memos/${existingMemo.id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
          }
        });
        if (!response.ok) throw new Error('Failed to delete memo');
        // Remove memo from local array
        memos.value = memos.value.filter(m => m.id !== existingMemo.id);
        memoMap.value.delete(`${item.id}_${formattedDate}`);
      }
    } else if (content) {
      // Create memo
      const response = await fetch('/memos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
        },
        body: JSON.stringify(memoData)
      });
      if (!response.ok) throw new Error('Failed to create memo');
      const newMemo = await response.json();
      // Add new memo to local array
      memos.value = [...memos.value, newMemo];
      memoMap.value.set(`${item.id}_${formattedDate}`, newMemo);
    }
  } catch (error) {
    console.error('Error setting memo:', error);
  }
};

const openProductPane = () => {
  emit('open-product-pane');
};

const closeProductPane = () => {
  emit('close-product-pane');
};


onMounted(() => {
  listStatistics();
});

watch(
  () => [statistics.value, selectedItems.value],
  () => {
    statistics.value.forEach(stat => {
      if (isSelected(stat.entity)) {
        stat.entity.selected = true;
      }
    });
  },
  { deep: true }
);
</script>

<style scoped>
body {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

th {
  position: sticky;
}

th:first-child,
td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

th {
  top: 0;
  z-index: 2;
}

.hasMemo {
  background-color: #fffae7 !important;
}

.weekend-saturday {
  background-color: #eef5ff;
}

.weekend-sunday {
  background-color: #feefef;
}

.input-disabled {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  color: #a0a0a0;
  cursor: not-allowed;
}

.scrollable-content {
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
</style>

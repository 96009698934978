<template>
  <div :style="sidebarStyle" class="flex-shrink-0 bg-gray-200 transition-all duration-300 ease-in-out">
    <div class="mb-4" v-if="sidebarOpen">
      <label for="period" class="block text-xs font-medium text-gray-700">Period</label>
      <select id="period" v-model="sidebarSelectedPeriod"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="quarterly">Quarterly</option>
        <option value="yearly">Yearly</option>
      </select>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'daily'">
      <label for="start_date" class="block text-xs font-medium text-gray-700">Start Date</label>
      <input type="date" id="start_date" v-model="sidebarStartDate" :min="props.startDay" :max="props.endDay"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'weekly'">
      <label for="start_week" class="block text-xs font-medium text-gray-700">Start Week</label>
      <input type="week" id="start_week" v-model="sidebarStartWeek" :min="props.startDay" :max="props.endDay"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'weekly'">
      <label for="end_week" class="block text-xs font-medium text-gray-700">End Week</label>
      <input type="week" id="end_week" v-model="sidebarEndWeek" :min="props.startDay" :max="props.endDay"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'daily'">
      <label for="end_date" class="block text-xs font-medium text-gray-700">End Date</label>
      <input type="date" id="end_date" v-model="sidebarEndDate" :min="props.startDay" :max="props.endDay"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'monthly'">
      <label for="start_month" class="block text-xs font-medium text-gray-700">Start Month</label>
      <input type="month" id="start_month" v-model="sidebarStartMonth" :min="props.startDay" :max="props.endDay"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'monthly'">
      <label for="end_month" class="block text-xs font-medium text-gray-700">End Month</label>
      <input type="month" id="end_month" v-model="sidebarEndMonth" :min="props.startDay" :max="props.endDay"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'quarterly'">
      <label for="start_quarter" class="block text-xs font-medium text-gray-700">Start Quarter</label>
      <select id="start_quarter" v-model="sidebarStartQuarter"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
        <option v-for="quarter in availableQuarters" :key="quarter" :value="quarter">{{ quarter }}</option>
      </select>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'quarterly'">
      <label for="end_quarter" class="block text-xs font-medium text-gray-700">End Quarter</label>
      <select id="end_quarter" v-model="sidebarEndQuarter"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
        <option v-for="quarter in availableQuarters" :key="quarter" :value="quarter">{{ quarter }}</option>
      </select>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'yearly'">
      <label for="start_year" class="block text-xs font-medium text-gray-700">Start Year</label>
      <select id="start_year" v-model="sidebarStartYear"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
        <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
      </select>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'yearly'">
      <label for="end_year" class="block text-xs font-medium text-gray-700">End Year</label>
      <select id="end_year" v-model="sidebarEndYear"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
        <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
      </select>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'daily'">
      <button @click="setPreset('today')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Today</button>
      <button @click="setPreset('yesterday')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Yesterday</button>
      <button @click="setPreset('past_7_days')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 7 Days</button>
      <button @click="setPreset('past_30_days')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 30
        Days</button>
      <button @click="setPreset('past_90_days')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 90
        Days</button>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'weekly'">
      <button @click="setPreset('this_week')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">This Week</button>
      <button @click="setPreset('last_week')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Last Week</button>
      <button @click="setPreset('past_4_weeks')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 4
        Weeks</button>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'monthly'">
      <button @click="setPreset('this_month')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">This Month</button>
      <button @click="setPreset('last_month')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Last Month</button>
      <button @click="setPreset('past_3_months')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 3
        Months</button>
      <button @click="setPreset('past_6_months')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 6
        Months</button>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'quarterly'">
      <button @click="setPreset('this_quarter')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">This
        Quarter</button>
      <button @click="setPreset('last_quarter')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Last
        Quarter</button>
      <button @click="setPreset('past_4_quarters')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 4
        Quarters</button>
    </div>
    <div class="mb-4" v-if="sidebarOpen && sidebarSelectedPeriod === 'yearly'">
      <button @click="setPreset('this_year')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">This Year</button>
      <button @click="setPreset('last_year')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Last Year</button>
      <button @click="setPreset('past_3_years')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 3
        Years</button>
      <button @click="setPreset('past_5_years')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded w-full">Past 5
        Years</button>
    </div>
    <button @click="handleLoadButton" class="mt-4 bg-black text-white py-2 px-4 rounded text-xs w-full"
      v-if="sidebarOpen">Go</button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const props = defineProps<{
  sidebarOpen: boolean;
  startDay: string;
  endDay: string;
}>();

const emit = defineEmits(['load']);

const sidebarStartDate = ref<string>('');
const sidebarEndDate = ref<string>('');
const sidebarStartWeek = ref<string>('');
const sidebarEndWeek = ref<string>('');
const sidebarStartMonth = ref<string>('');
const sidebarEndMonth = ref<string>('');
const sidebarStartQuarter = ref<string>('');
const sidebarEndQuarter = ref<string>('');
const sidebarStartYear = ref<string>('');
const sidebarEndYear = ref<string>('');
const sidebarSelectedPeriod = ref<string>('daily');

const sidebarStyle = computed(() => ({
  width: props.sidebarOpen ? '16rem' : '0',
  overflow: props.sidebarOpen ? 'auto' : 'hidden',
  padding: props.sidebarOpen ? '1rem' : '0'
}));

const availableMonths = computed<string[]>(() => {
  const months: string[] = [];
  let current = dayjs(props.startDay).startOf('month');
  const end = dayjs(props.endDay).endOf('month');

  while (current.isBefore(end) || current.isSame(end, 'month')) {
    months.push(current.format('YYYY-MM'));
    current = current.add(1, 'month');
  }

  return months;
});

const availableQuarters = computed<string[]>(() => {
  const quarters: string[] = [];
  let currentYear = parseInt(dayjs(props.startDay).year().toString());
  let currentQuarter = Math.ceil((dayjs(props.startDay).month() + 1) / 3);
  const endYear = parseInt(dayjs(props.endDay).year().toString());
  const endQuarter = Math.ceil((dayjs(props.endDay).month() + 1) / 3);

  while (currentYear < endYear || (currentYear === endYear && currentQuarter <= endQuarter)) {
    quarters.push(`${currentYear}Q${currentQuarter}`);
    if (currentQuarter === 4) {
      currentQuarter = 1;
      currentYear++;
    } else {
      currentQuarter++;
    }
  }

  return quarters;
});

const availableYears = computed<string[]>(() => {
  const years: string[] = [];
  let current = dayjs(props.startDay).startOf('year');
  const end = dayjs(props.endDay).endOf('year');

  while (current.isBefore(end) || current.isSame(end, 'year')) {
    years.push(current.year().toString());
    current = current.add(1, 'year');
  }

  return years;
});

const calculateWeeklyPreset = (weeksAgo) => {
  const startDate = dayjs().subtract(weeksAgo, 'week').startOf('isoWeek');
  const endDate = dayjs().endOf('isoWeek');

  const startWeek = startDate.isoWeek();
  const endWeek = endDate.isoWeek();

  const startYear = startDate.year();
  const endYear = endDate.year();

  return {
    startWeek: `${startYear}-W${String(startWeek).padStart(2, '0')}`,
    endWeek: `${endYear}-W${String(endWeek).padStart(2, '0')}`
  };
};

const setPreset = (preset) => {
  const today = dayjs();
  let startDate, endDate, weeklyPreset;

  switch (preset) {
    // Daily Presets
    case 'today':
      startDate = today;
      endDate = today;
      sidebarStartDate.value = startDate.format('YYYY-MM-DD');
      sidebarEndDate.value = endDate.format('YYYY-MM-DD');
      break;
    case 'yesterday':
      startDate = today.subtract(1, 'day');
      endDate = startDate;
      sidebarStartDate.value = startDate.format('YYYY-MM-DD');
      sidebarEndDate.value = endDate.format('YYYY-MM-DD');
      break;
    case 'past_7_days':
      startDate = today.subtract(7, 'day');
      endDate = today;
      sidebarStartDate.value = startDate.format('YYYY-MM-DD');
      sidebarEndDate.value = endDate.format('YYYY-MM-DD');
      break;
    case 'past_30_days':
      startDate = today.subtract(30, 'day');
      endDate = today;
      sidebarStartDate.value = startDate.format('YYYY-MM-DD');
      sidebarEndDate.value = endDate.format('YYYY-MM-DD');
      break;
    case 'past_90_days':
      startDate = today.subtract(90, 'day');
      endDate = today;
      sidebarStartDate.value = startDate.format('YYYY-MM-DD');
      sidebarEndDate.value = endDate.format('YYYY-MM-DD');
      break;

    // Weekly Presets
    case 'this_week':
      console.log(sidebarStartWeek.value);
      console.log(sidebarEndWeek.value);
      weeklyPreset = calculateWeeklyPreset(0);
      sidebarStartWeek.value = weeklyPreset.startWeek;
      sidebarEndWeek.value = weeklyPreset.endWeek;
      console.log("Last Week:", sidebarStartWeek.value, sidebarEndWeek.value);
      break;
    case 'last_week':
      weeklyPreset = calculateWeeklyPreset(1);
      sidebarStartWeek.value = weeklyPreset.startWeek;
      sidebarEndWeek.value = weeklyPreset.endWeek;
      console.log("Last Week:", sidebarStartWeek.value, sidebarEndWeek.value);
      break;
    case 'past_4_weeks':
      weeklyPreset = calculateWeeklyPreset(4);
      sidebarStartWeek.value = weeklyPreset.startWeek;
      sidebarEndWeek.value = weeklyPreset.endWeek;
      console.log("Past 4 Weeks:", sidebarStartWeek.value, sidebarEndWeek.value);
      break;

    // Monthly Presets
    case 'this_month':
      startDate = today.startOf('month');
      endDate = today.endOf('month');
      sidebarStartMonth.value = startDate.format('YYYY-MM');
      sidebarEndMonth.value = endDate.format('YYYY-MM');
      break;
    case 'last_month':
      startDate = today.subtract(1, 'month').startOf('month');
      endDate = today.subtract(1, 'month').endOf('month');
      sidebarStartMonth.value = startDate.format('YYYY-MM');
      sidebarEndMonth.value = endDate.format('YYYY-MM');
      break;
    case 'past_3_months':
      startDate = today.subtract(3, 'month').startOf('month');
      endDate = today.endOf('month');
      sidebarStartMonth.value = startDate.format('YYYY-MM');
      sidebarEndMonth.value = endDate.format('YYYY-MM');
      break;
    case 'past_6_months':
      startDate = today.subtract(6, 'month').startOf('month');
      endDate = today.endOf('month');
      sidebarStartMonth.value = startDate.format('YYYY-MM');
      sidebarEndMonth.value = endDate.format('YYYY-MM');
      break;

    // Quarterly Presets
    case 'this_quarter':
      const currentQuarter = Math.ceil((today.month() + 1) / 3);
      sidebarStartQuarter.value = `${today.year()}Q${currentQuarter}`;
      sidebarEndQuarter.value = `${today.year()}Q${currentQuarter}`;
      break;
    case 'last_quarter':
      const lastQuarterDate = today.subtract(3, 'month');
      const lastQuarter = Math.ceil((lastQuarterDate.month() + 1) / 3);
      sidebarStartQuarter.value = `${lastQuarterDate.year()}Q${lastQuarter}`;
      sidebarEndQuarter.value = `${lastQuarterDate.year()}Q${lastQuarter}`;
      break;
    case 'past_4_quarters':
      sidebarStartQuarter.value = `${today.subtract(1, 'year').year()}Q${Math.ceil((today.subtract(1, 'year').month() + 1) / 3)}`;
      sidebarEndQuarter.value = `${today.year()}Q${Math.ceil((today.month() + 1) / 3)}`;
      break;

    // Yearly Presets
    case 'this_year':
      sidebarStartYear.value = today.startOf('year').format('YYYY');
      sidebarEndYear.value = today.endOf('year').format('YYYY');
      break;
    case 'last_year':
      sidebarStartYear.value = today.subtract(1, 'year').startOf('year').format('YYYY');
      sidebarEndYear.value = today.subtract(1, 'year').endOf('year').format('YYYY');
      break;
    case 'past_3_years':
      sidebarStartYear.value = today.subtract(3, 'year').startOf('year').format('YYYY');
      sidebarEndYear.value = today.endOf('year').format('YYYY');
      break;
    case 'past_5_years':
      sidebarStartYear.value = today.subtract(5, 'year').startOf('year').format('YYYY');
      sidebarEndYear.value = today.endOf('year').format('YYYY');
      break;
  }

  // Ensure dates are within the available range
  if (sidebarStartDate.value < props.startDay) {
    sidebarStartDate.value = props.startDay;
  }
  if (sidebarEndDate.value > props.endDay) {
    sidebarEndDate.value = props.endDay;
  }

  // TODO: 이거 문제라서 나중에 업데이트해야 한다.
  // if (sidebarStartWeek.value < `${props.startDay.slice(0, 4)}-W${props.startDay.slice(5, 7)}`) {
  //   sidebarStartWeek.value = `${props.startDay.slice(0, 4)}-W${props.startDay.slice(5, 7)}`;
  // }
  // if (sidebarEndWeek.value > `${props.endDay.slice(0, 4)}-W${props.endDay.slice(5, 7)}`) {
  //   sidebarEndWeek.value = `${props.endDay.slice(0, 4)}-W${props.endDay.slice(5, 7)}`;
  // }

  if (sidebarStartMonth.value < props.startDay.slice(0, 7)) {
    sidebarStartMonth.value = props.startDay.slice(0, 7);
  }
  if (sidebarEndMonth.value > props.endDay.slice(0, 7)) {
    sidebarEndMonth.value = props.endDay.slice(0, 7);
  }
  if (sidebarStartQuarter.value < `${props.startDay.slice(0, 4)}Q1`) {
    sidebarStartQuarter.value = `${props.startDay.slice(0, 4)}Q1`;
  }
  if (sidebarEndQuarter.value > `${props.endDay.slice(0, 4)}Q4`) {
    sidebarEndQuarter.value = `${props.endDay.slice(0, 4)}Q4}`;
  }
  if (sidebarStartYear.value < props.startDay.slice(0, 4)) {
    sidebarStartYear.value = props.startDay.slice(0, 4);
  }
  if (sidebarEndYear.value > props.endDay.slice(0, 4)) {
    sidebarEndYear.value = props.endDay.slice(0, 4);
  }
};

const handleLoadButton = () => {
  emit('load', {
    sidebarSelectedPeriod: sidebarSelectedPeriod.value,
    sidebarStartDate: sidebarStartDate.value.replace(/-/g, ''),
    sidebarEndDate: sidebarEndDate.value.replace(/-/g, ''),
    sidebarStartWeek: sidebarStartWeek.value.replace('-W', ''), // Remove '-W' from week format
    sidebarEndWeek: sidebarEndWeek.value.replace('-W', ''), // Remove '-W' from week format
    sidebarStartMonth: sidebarStartMonth.value.replace(/-/g, ''),
    sidebarEndMonth: sidebarEndMonth.value.replace(/-/g, ''),
    sidebarStartQuarter: sidebarStartQuarter.value,
    sidebarEndQuarter: sidebarEndQuarter.value,
    sidebarStartYear: sidebarStartYear.value,
    sidebarEndYear: sidebarEndYear.value
  });
};
</script>

<template>
  <div class="p-8">
    <div class="mt-4 text-xs">
      <label for="start_date" class="block text-xs font-medium">Start Date</label>
      <input type="date" id="start_date" v-model="startDate"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mt-4 text-xs">
      <label for="end_date" class="block text-xs font-medium">End Date</label>
      <input type="date" id="end_date" v-model="endDate"
        class="mt-1 block w-full shadow-sm text-xs border-gray-300 rounded-md">
    </div>
    <div class="mt-4">
      <button @click="setPreset('today')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded">Today</button>
      <button @click="setPreset('yesterday')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded">Yesterday</button>
      <button @click="setPreset('past_7_days')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded">Past 7 Days</button>
      <button @click="setPreset('past_30_days')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded">Past 30 Days</button>
      <button @click="setPreset('past_90_days')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded">Past 90 Days</button>
      <button @click="setPreset('past_3_years')"
        class="mt-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 mr-2 text-xs rounded">Past 3 Years</button>
    </div>
    <div class="mt-6">
      <label for="force_update" class="block text-xs flex items-center">
        <input type="checkbox" id="force_update" v-model="forceUpdate" class="mr-2">강제 업데이트
      </label>
    </div>
    <div class="mt-4 text-xs">
      <button v-if="!loading" @click="listProductSales"
        class="bg-black w-full text-white py-2 px-4 rounded-sm">Update</button>
      <button v-if="loading" @click="stopProcess"
        class="bg-red-500 w-full text-white py-2 px-4 rounded-sm">Stop</button>
    </div>
    <div v-if="loading" class="mt-6 flex items-center">
      <div class="w-full bg-gray-200 rounded-full h-4">
        <div :style="{ width: progressWidth }" class="bg-green-800 h-4 rounded-full"></div>
      </div>
      <span class="ml-4 text-xs">{{ progressPercentage }}%</span>
    </div>
    <div v-if="loading" class="mt-4 text-xs font-medium text-gray-700">Current Updating Date: {{ currentUpdatingDate }}
    </div>
    <div v-if="loading" class="mt-4 text-xs font-medium text-gray-700">Time Elapsed: {{ formattedTimeElapsed }}</div>
    <div v-if="loading" class="mt-4 text-xs font-medium text-gray-700">Estimated Remaining Time: {{
      formattedEstimatedTimeRemaining }}</div>
    <div v-if="progress.length > 0" class="mt-8 overflow-auto max-h-80 text-xs">
      <p v-for="entry in progress" :key="entry.date || entry" v-html="formatLogEntry(entry)" class="my-2.5"></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

const props = defineProps<{
  currentMallId: number;
  startDay: string | null;
  endDay: string | null;
}>();

const sales = ref<any[]>([]);
const error = ref<string | null>(null);
const loading = ref<boolean>(false);
const startDate = ref<string>('');
const endDate = ref<string>('');
const progress = ref<any[]>([]);
const stopRequested = ref<boolean>(false);
const progressPercentage = ref<number>(0);
const totalDays = ref<number>(0);
const completedDays = ref<number>(0);
const currentUpdatingDate = ref<string>('');
const lastSuccessfulDate = ref<string>('');
const forceUpdate = ref<boolean>(false);
const timeElapsed = ref<number>(0);
const intervalId = ref<ReturnType<typeof setInterval> | null>(null);

onMounted(() => {
  const today = dayjs();
  endDate.value = today.format('YYYY-MM-DD');
  startDate.value = props.endDay ? props.endDay : today.subtract(3, 'years').format('YYYY-MM-DD');
});

const progressWidth = computed(() => `${progressPercentage.value}%`);

const setPreset = (preset: string) => {
  const today = dayjs();
  switch (preset) {
    case 'today':
      startDate.value = today.format('YYYY-MM-DD');
      endDate.value = today.format('YYYY-MM-DD');
      break;
    case 'yesterday':
      startDate.value = today.subtract(1, 'day').format('YYYY-MM-DD');
      endDate.value = today.subtract(1, 'day').format('YYYY-MM-DD');
      break;
    case 'past_7_days':
      startDate.value = today.subtract(7, 'day').format('YYYY-MM-DD');
      endDate.value = today.format('YYYY-MM-DD');
      break;
    case 'past_30_days':
      startDate.value = today.subtract(30, 'day').format('YYYY-MM-DD');
      endDate.value = today.format('YYYY-MM-DD');
      break;
    case 'past_90_days':
      startDate.value = today.subtract(90, 'day').format('YYYY-MM-DD');
      endDate.value = today.format('YYYY-MM-DD');
      break;
    case 'past_3_years':
      startDate.value = today.subtract(3, 'years').format('YYYY-MM-DD');
      endDate.value = today.format('YYYY-MM-DD');
      break;
  }
};

const listProductSales = async () => {
  // Reset progress variables before starting a new update
  sales.value = [];
  progress.value = [];
  stopRequested.value = false;
  progressPercentage.value = 0;
  lastSuccessfulDate.value = '';
  totalDays.value = 0;
  completedDays.value = 0;
  timeElapsed.value = 0;

  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }

  intervalId.value = setInterval(() => {
    timeElapsed.value++;
  }, 1000);

  if (!startDate.value || !endDate.value) {
    error.value = 'Start date and end date are required.';
    return;
  }

  loading.value = true;
  error.value = null;

  console.log('Force Update:', forceUpdate.value); // Log the forceUpdate value

  progress.value.push(`<span class='font-medium bg-gray-800 text-white p-1'>Update started from ${startDate.value} to ${endDate.value}</span>`);

  const start = dayjs(startDate.value);
  const end = dayjs(endDate.value);
  const dates: string[] = [];
  let currentDate = start;

  while (currentDate.isSameOrBefore(end)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  totalDays.value = dates.length;

  try {
    for (const date of dates) {
      if (stopRequested.value) {
        progress.value.push(`<span class='font-medium bg-red-400 text-white p-1'>Update stopped by user.</span>`);
        progressPercentage.value = 0;
        if (intervalId.value !== null) {
          clearInterval(intervalId.value);
        }
        break;
      }

      const dateObj = dayjs(date);
      // Skip API call if date is before endDay and forceUpdate is false
      if (!forceUpdate.value && props.endDay && dateObj.isSameOrBefore(dayjs(props.endDay))) {
        progress.value.push(`<span class="p-1">${dateObj.format('YYYYMMDD')} - Skipped</span>`);
        completedDays.value++;
        progressPercentage.value = Math.floor((completedDays.value / totalDays.value) * 100);
        continue;
      }

      currentUpdatingDate.value = date;
      const created = await fetchSalesForDate(date);
      if (created) {
        lastSuccessfulDate.value = date;
      }
      completedDays.value++;
      progressPercentage.value = Math.floor((completedDays.value / totalDays.value) * 100);
      await new Promise(resolve => setTimeout(resolve, 500)); // Wait for 0.666 seconds before the next API call
    }

    if (!stopRequested.value) {
      progress.value.push(`<span class='font-medium bg-green-600 text-white p-1'>Update completed!</span>`);
      progressPercentage.value = 0;
      if (intervalId.value !== null) {
        clearInterval(intervalId.value);
      }
    }
  } catch (err) {
    progress.value.push(`Error: ${err.message}`);
    if (intervalId.value !== null) {
      clearInterval(intervalId.value);
    }
  } finally {
    loading.value = false;
  }

  // Only update mall dates if at least one date was processed
  if (lastSuccessfulDate.value !== null) {
    updateMallDates();
  }
};

const fetchSalesForDate = async (date: string): Promise<boolean> => {
  try {
    const response = await fetch('/list_product_sales', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      },
      body: JSON.stringify({ start_date: date, end_date: date, force_update: forceUpdate.value })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    let created = false;
    data.actions.forEach((action: any) => {
      sales.value.push(action);
      progress.value.push(action);
      if (action.action === 'created') {
        created = true;
      }
    });
    return created;
  } catch (err) {
    progress.value.push({ date, action: 'skipped', message: ' (already exist)' });
    error.value = err.message;
    return false;
  }
};

const stopProcess = () => {
  stopRequested.value = true;
  fetch('/stop_process', { method: 'POST', headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '' } });

  // Reset progress when stopped
  progressPercentage.value = 0;
  completedDays.value = 0;
  totalDays.value = 0;
  timeElapsed.value = 0;
  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }
  progress.value.push(`<span class='font-medium bg-yellow-400 mt-4 text-white p-1'>Progress reset after stop.</span>`);
};

const formatLogEntry = (entry: any) => {
  if (typeof entry === 'string') {
    return `<span>${entry}</span>`;
  }

  const actionClass = entry.action === 'created' ? 'text-green-600 p-1' : entry.action === 'updated' ? 'text-blue-600 p-1' : 'text-gray-600 p-1';
  return `<span class="${actionClass}">${entry.date} - ${entry.action}</span>`;
};

const updateMallDates = async () => {
  try {
    const response = await fetch('/update_start_end_day', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      },
      body: JSON.stringify({
        mall_id: props.currentMallId,
        start_day: startDate.value,
        end_day: lastSuccessfulDate.value
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data.message);
  } catch (err) {
    console.error('Failed to update mall dates:', err.message);
  }
};

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};

const formattedTimeElapsed = computed(() => formatTime(timeElapsed.value));

const estimatedRemainingTime = computed(() => {
  if (completedDays.value === 0) return 0;
  const timePerDay = timeElapsed.value / completedDays.value;
  const remainingDays = totalDays.value - completedDays.value;
  return Math.floor(timePerDay * remainingDays);
});

const formattedEstimatedTimeRemaining = computed(() => formatTime(estimatedRemainingTime.value));

watch(loading, (newValue) => {
  if (!newValue && intervalId.value !== null) {
    clearInterval(intervalId.value);
  }
});
</script>

<style scoped>
/* Add any styles if needed */
</style>

<template>
  <div v-if="isInitialized" class="h-screen flex flex-col relative">
    <!-- Loading Screen -->
    <div v-if="isLoading" class="loading-screen flex items-center justify-center">
      <div class="loading-animation"></div>
    </div>

    <!-- Tabs Navigation -->
    <div class="flex h-16 text-center bg-gray-800 text-white fixed w-full z-10 justify-between items-center px-2 py-2">
      <div class="flex items-center">
        <button @click="toggleSidebar" class="rounded-sm py-2 px-3 text-xs">
          <font-awesome-icon :icon="['fas', 'bars']" />
        </button>
        <div @click="selectTab('statistics')" class="flex items-center cursor-pointer">
          <h1 class="ml-1 text-3xl font-bold zain-extrabold">mallscan</h1>
          <div class="ml-3 font-medium text-xs">{{ mallId }}</div>
        </div>
      </div>
      <nav class="flex space-x-8 list-none text-sm px-4 items-center">
        <li v-if="startDay && endDay" class="text-xs cursor-pointer" @click="selectTab('loadData')">
          <span>분석 가능 기간 : {{ startDay }} ~ {{ endDay }}</span>
          <button class="ml-2">
            <font-awesome-icon v-if="needsUpdate" class="text-yellow-300" :icon="['fas', 'circle-exclamation']" />
            <font-awesome-icon v-else class="text-green-400" :icon="['fas', 'circle-check']" />
          </button>
        </li>
        <li v-else class="text-xs cursor-pointer">
          <span>먼저 데이터를 로드하세요</span>
          <font-awesome-icon v-if="needsUpdate" class="text-yellow-300 ml-2 cursor-pointer"
            :icon="['fas', 'circle-exclamation']" @click="selectedTab = 'loadData'" />
        </li>
        <li @click="installAnalyticsScript" class="cursor-pointer text-xs">
          <span>몰스캔 애널리틱스 설치</span>
          <font-awesome-icon class="ml-2" :icon="['fas', 'tag']" />
        </li>
        <li @click="logout" class="cursor-pointer text-xs">로그아웃</li>
      </nav>
    </div>

    <!-- Spacer to account for fixed header -->
    <div class="h-16"></div>

    <!-- Tab Content -->
    <div class="flex-1 overflow-hidden">
      <div v-if="selectedTab === 'statistics'" class="h-full">
        <StatisticsTab :currentMallId="currentMallId" :sidebarOpen="sidebarOpen" :startDay="startDay || ''"
          :endDay="endDay || ''" :productPaneOpen="productPaneOpen" @toggle-sidebar="toggleSidebar"
          @open-product-pane="openProductPane" @close-product-pane="closeProductPane" @loading="handleLoading" />
      </div>
      <div v-if="selectedTab === 'loadData'" class="h-full">
        <LoadDataTab :currentMallId="currentMallId" :startDay="startDay || ''" :endDay="endDay || ''"
          @loading="handleLoading" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import dayjs from 'dayjs';
import StatisticsTab from './StatisticsTab.vue';
import LoadDataTab from './LoadDataTab.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const selectedTab = ref<string>('statistics');
const sidebarOpen = ref<boolean>(true);
const chartPaneOpen = ref<boolean>(false);
const productPaneOpen = ref<boolean>(false);
const mallId = ref<string>('');
const sessionId = ref<string>('');
const currentMallId = ref<number>(0);
const version = ref<string>('');
const startDay = ref<string | null>(null);
const endDay = ref<string | null>(null);
const mallscanAccessToken = ref<string | null>(null);

const needsUpdate = ref<boolean>(true);
const isLoading = ref<boolean>(false);
const isInitialized = ref<boolean>(false);

// const tabClass = (tab: string) => {
//   return selectedTab.value === tab ? 'font-semibold' : 'font-normal';
// };

const toggleSidebar = () => {
  sidebarOpen.value = !sidebarOpen.value;

  if (productPaneOpen) productPaneOpen.value = false;

  if (sidebarOpen.value) {
    chartPaneOpen.value = false;
  }
};

const selectTab = (tab: string) => {
  selectedTab.value = tab;

  if (productPaneOpen) productPaneOpen.value = false;
}

// const toggleChartPane = (open: boolean) => {
//   chartPaneOpen.value = open;
//   if (chartPaneOpen.value) {
//     sidebarOpen.value = false;
//   }
// };

const openProductPane = (open: boolean) => {
  productPaneOpen.value = true;
};

const closeProductPane = (open: boolean) => {
  productPaneOpen.value = false;
};

const logout = async () => {
  isLoading.value = true;
  try {
    const response = await fetch('/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      }
    });
    if (response.ok) {
      window.location.href = '/'; // Redirect to the root path or any other desired path
    } else {
      console.error('Logout failed');
    }
  } catch (error) {
    isLoading.value = false;
    console.error('Logout error:', error);
  }
};

const handleLoading = (loadingStatus: boolean) => {
  isLoading.value = loadingStatus;
};

const installAnalyticsScript = async () => {
  try {
    const response = await fetch('/ensure_script_installed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      }
    });

    if (response.ok) {
      console.log('Analytics script installed successfully');
      alert('Analytics script installed successfully');
    } else {
      const errorText = await response.text();
      console.error('Failed to install analytics script:', errorText);
      alert(`Failed to install analytics script: ${errorText}`);
    }
  } catch (error) {
    console.error('Error installing analytics script:', error);
    alert('Error installing analytics script');
  }
}

onMounted(() => {
  const appElement = document.getElementById('app');
  if (appElement) {
    mallId.value = appElement.dataset.mallId || '';
    sessionId.value = appElement.dataset.sessionId || '';
    currentMallId.value = Number(appElement.dataset.currentMallId);
    version.value = appElement.dataset.version || '';
    startDay.value = appElement.dataset.startDay || null;
    endDay.value = appElement.dataset.endDay || null;
    mallscanAccessToken.value = appElement.dataset.mallscanAccessToken || null;

    if (mallscanAccessToken.value) {
      // Send the message after a delay to ensure the content script is ready
      setTimeout(() => {
        window.postMessage({ action: 'storeToken', token: mallscanAccessToken.value, mallId: mallId.value, sessionId: sessionId.value }, '*');
      }, 100); // Adjust the delay as necessary
    } else {
      console.error('Failed to retrieve the token');
    }

    const today = dayjs();
    if (!endDay.value || dayjs(endDay.value).isBefore(today.subtract(3, 'day'))) {
      needsUpdate.value = true;
    } else {
      needsUpdate.value = false;
    }

    // Set isInitialized to true after setting up
    isInitialized.value = true;
  }
});
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-animation {
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #23475f;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div v-if="productPaneOpen" class="product-pane-overlay">
    <div class="flex justify-between items-center">
      <div class="flex items-center text-sm">
        <button class="font-medium text-white bg-[#171717] px-4 py-2 rounded-sm w-36">상품/제품 맵핑</button>
        <button class="w-36 px-4 py-2 rounded-sm">제품 상세정보</button>
      </div>
      <div @click="closeProductPane">
        <font-awesome-icon :icon="['fas', 'square-xmark']" class="cursor-pointer text-lg" />
      </div>
    </div>
    <div class="mt-4 flex-1 flex flex-col overflow-hidden">
      <div class="flex justify-between">
        <div class="flex items-center space-x-2 text-sm">
          <button v-if="canUndo" @click="undo" class="px-4 py-2 bg-[#171717] text-white rounded-sm"><font-awesome-icon :icon="['fas', 'rotate-backward']" /></button>
          <button v-if="canRedo" @click="redo" class="px-4 py-2 bg-[#171717] text-white rounded-sm"><font-awesome-icon :icon="['fas', 'rotate-forward']" /></button>
        </div>
        <div class="flex items-center space-x-2 text-sm">
          <button @click="promptCreateProduct" class="mb-2 px-4 py-2 bg-[#171717] text-white rounded-sm">제품추가</button>
          <button @click="saveChanges" class="mb-2 px-4 py-2 bg-[#171717] text-white rounded-sm">변경사항 저장</button>
        </div>
      </div>
      <div class="w-full flex justify-between space-x-2 my-2 text-lg">
        <div class="flex-1 border-b-2 border-black text-center font-bold py-1">
          <h1>상품</h1>
        </div>
        <div class="flex-1 border-b-2 border-black text-center font-bold py-1">
          <h1>제품</h1>
        </div>
      </div>
      <div class="flex-1 flex justify-between mt-2 overflow-hidden">
        <div class="flex-1 overflow-y-auto p-2">
          <input type="text" v-model="itemSearchQuery" @input="searchItems" placeholder="Search items..."
            class="mb-2 p-2 border rounded w-full text-sm" />
          <div class="flex items-center">
            <button @click="toggleSelectAllItems" class="p-2 bg-blue-500 text-white rounded-sm text-xs">{{
              allItemsSelected ? '모두해제' : '모두선택' }}</button>
            <p class="ml-2 text-sm">{{ filteredItems.length }} items available ({{ selectedItems.length }} selected)</p>
          </div>
          <ul v-if="filteredItems && filteredItems.length > 0">
            <li v-for="(item, index) in filteredItems" :key="item.id" @click="handleItemClick(item.id, index, $event)"
              :class="{ 'bg-blue-200': selectedItems.includes(item.id) }" class="cursor-pointer p-2 text-sm"
              draggable="true" @dragstart="onDragStart($event, item.id)" @dragend="onDragEnd">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="flex-1 overflow-y-auto p-2">
          <input type="text" v-model="productSearchQuery" @input="searchProducts" placeholder="Search products..."
            class="mb-2 p-2 border rounded w-full text-sm" />
          <div class="flex mb-2 text-xs">
            <button @click="collapseAllProducts" class="p-2 bg-gray-500 text-white rounded-sm mr-2">모두접기</button>
            <button @click="expandAllProducts" class="p-2 bg-gray-500 text-white rounded-sm ">모두펼치기</button>
          </div>
          <ul v-if="filteredProducts && filteredProducts.length > 0">
            <li v-for="product in filteredProducts" :key="product.id" class="p-2 bg-gray-200 mb-2" @dragover.prevent
              @drop="onDropItem($event, product.id)">
              <div class="flex justify-between items-center">
                <div><span class="font-bold text-md">{{ product.name }}</span> <span class="text-xs">({{ product.items ?
                  product.items.length : 0 }} items)</span></div>
                <div class="flex items-center space-x-2 text-sm">
                  <button @click.stop="toggleCollapse(product.id)"
                    class="px-2 py-1 bg-gray-500 text-white rounded-sm">{{ isCollapsed(product.id) ? '펼치기' : '접기'
                    }}</button>
                  <button @click.stop="editProduct(product)"
                    class="px-2 py-1 bg-blue-500 text-white rounded-sm">수정</button>
                  <button @click.stop="deleteProduct(product.id)"
                    class="px-2 py-1 bg-red-500 text-white rounded-sm">삭제</button>
                </div>
              </div>
              <ul v-if="!isCollapsed(product.id)" class="ml-4 mt-2">
                <li v-for="item in product.items" :key="item.id"
                  class="p-2 bg-white mb-1 cursor-pointer flex justify-between items-center text-sm">
                  {{ item.name }}
                  <button @click.stop="removeMapping(item.id, product.id)" class="text-red-500">X</button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="dragging" class="dragging-items">
      <p>Dragging {{ selectedItems.length }} item(s)</p>
    </div>
    <div v-if="creatingProduct" class="create-product-modal">
      <div class="modal-content">
        <h3>Create Product</h3>
        <input type="text" v-model="newProductName" placeholder="Enter product name" class="input" />
        <button @click="createProduct" class="p-2 bg-green-500 text-white rounded mt-2">Create</button>
        <button @click="cancelCreate" class="p-2 bg-gray-500 text-white rounded mt-2 ml-2">Cancel</button>
      </div>
    </div>
    <div v-if="editingProduct" class="edit-product-modal">
      <div class="modal-content">
        <h3>Edit Product</h3>
        <input type="text" v-model="editingProduct.name" class="input" />
        <button @click="saveProduct" class="p-2 bg-green-500 text-white rounded mt-2">Save</button>
        <button @click="cancelEdit" class="p-2 bg-gray-500 text-white rounded mt-2 ml-2">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';

interface Item {
  id: string;
  name: string;
}

interface Product {
  id: string;
  name: string;
  items: Item[];
}

const props = defineProps<{
  productPaneOpen: boolean;
}>();

const emit = defineEmits(['close-product-pane']);

const items = ref<Item[]>([]);
const products = ref<Product[]>([]);
const selectedItems = ref<string[]>([]);
const dragging = ref(false);
const editingProduct = ref<Product | null>(null);
const creatingProduct = ref(false);
const newProductName = ref('');
const collapsedProducts = ref(new Set<string>());
const allItemsSelected = ref(false);
const lastSelectedIndex = ref<number | null>(null);

const itemSearchQuery = ref('');
const productSearchQuery = ref('');

const filteredItems = ref<Item[]>([]);
const filteredProducts = ref<Product[]>([]);

const undoStack = ref<any[]>([]);
const redoStack = ref<any[]>([]);

const fetchItems = async () => {
  const url = '/items';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch items');
    }
    const data = await response.json();
    items.value = Array.isArray(data) ? data : [];
    filterItems();
  } catch (error) {
    console.error(error);
  }
};

const fetchProducts = async () => {
  const url = '/products';
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }
    const data = await response.json();
    products.value = Array.isArray(data) ? data : [];
    products.value.forEach(product => {
      if (!product.items) {
        product.items = [];
      }
    });
    filterProducts();
  } catch (error) {
    console.error(error);
  }
};

const filterItems = () => {
  const usedItemIds = new Set(products.value.flatMap(product => product.items ? product.items.map(item => item.id) : []));
  filteredItems.value = items.value.filter(item =>
    item.name.toLowerCase().includes(itemSearchQuery.value.toLowerCase()) && !usedItemIds.has(item.id)
  );
};

const filterProducts = () => {
  filteredProducts.value = products.value.filter(product =>
    product.name.toLowerCase().includes(productSearchQuery.value.toLowerCase())
  );
};

const searchItems = () => {
  filterItems();
};

const searchProducts = () => {
  filterProducts();
};

const saveState = () => {
  undoStack.value.push({
    items: JSON.parse(JSON.stringify(items.value)),
    products: JSON.parse(JSON.stringify(products.value)),
    selectedItems: JSON.parse(JSON.stringify(selectedItems.value)),
  });
  redoStack.value = [];
};

const undo = () => {
  if (undoStack.value.length > 0) {
    const currentState = {
      items: JSON.parse(JSON.stringify(items.value)),
      products: JSON.parse(JSON.stringify(products.value)),
      selectedItems: JSON.parse(JSON.stringify(selectedItems.value)),
    };
    redoStack.value.push(currentState);
    const previousState = undoStack.value.pop();
    items.value = previousState.items;
    products.value = previousState.products;
    selectedItems.value = previousState.selectedItems;
    filterItems();
    filterProducts();
  }
};

const redo = () => {
  if (redoStack.value.length > 0) {
    const currentState = {
      items: JSON.parse(JSON.stringify(items.value)),
      products: JSON.parse(JSON.stringify(products.value)),
      selectedItems: JSON.parse(JSON.stringify(selectedItems.value)),
    };
    undoStack.value.push(currentState);
    const nextState = redoStack.value.pop();
    items.value = nextState.items;
    products.value = nextState.products;
    selectedItems.value = nextState.selectedItems;
    filterItems();
    filterProducts();
  }
};

const canUndo = computed(() => undoStack.value.length > 0);
const canRedo = computed(() => redoStack.value.length > 0);

const toggleSelection = (itemId) => {
  if (selectedItems.value.includes(itemId)) {
    selectedItems.value = selectedItems.value.filter(id => id !== itemId);
  } else {
    selectedItems.value.push(itemId);
  }
};

const toggleSelectAllItems = () => {
  if (allItemsSelected.value) {
    selectedItems.value = [];
  } else {
    filteredItems.value.forEach(item => {
      if (!selectedItems.value.includes(item.id)) {
        selectedItems.value.push(item.id);
      }
    });
  }
  allItemsSelected.value = !allItemsSelected.value;
};

const handleItemClick = (itemId, index, event) => {
  if (event.shiftKey && lastSelectedIndex.value !== null) {
    const start = Math.min(lastSelectedIndex.value, index);
    const end = Math.max(lastSelectedIndex.value, index);
    for (let i = start; i <= end; i++) {
      const id = filteredItems.value[i].id;
      if (!selectedItems.value.includes(id)) {
        selectedItems.value.push(id);
      }
    }
  } else {
    toggleSelection(itemId);
  }
  lastSelectedIndex.value = index;
};

const onDragStart = (event, itemId, productId = null) => {
  if (!productId && selectedItems.value.length === 0) {
    selectedItems.value.push(itemId);
  }
  dragging.value = true;
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('text/plain', JSON.stringify({ itemIds: selectedItems.value, productId }));
};

const onDragEnd = () => {
  dragging.value = false;
};

const onDropItem = (event, targetProductId) => {
  saveState();
  const draggedData = JSON.parse(event.dataTransfer.getData('text/plain'));
  const targetProduct = products.value.find(product => product.id === targetProductId);
  if (targetProduct) {
    const newItems = items.value.filter(item => draggedData.itemIds.includes(item.id));
    newItems.forEach(item => {
      const itemIndex = items.value.findIndex(i => i.id === item.id);
      if (itemIndex !== -1) {
        items.value.splice(itemIndex, 1);
      }
      targetProduct.items.push(item);
    });
  }
  dragging.value = false;
  selectedItems.value = [];
  allItemsSelected.value = false;
  filterItems();
};

const removeMapping = (itemId, productId) => {
  saveState();
  const product = products.value.find(product => product.id === productId);
  if (product) {
    const itemIndex = product.items.findIndex(item => item.id === itemId);
    if (itemIndex !== -1) {
      const [item] = product.items.splice(itemIndex, 1);
      items.value.push(item);
      filterItems();
    }
  }
};

const promptCreateProduct = () => {
  creatingProduct.value = true;
};

const createProduct = () => {
  if (newProductName.value.trim()) {
    saveState();
    const newProduct = { id: `new-${Date.now()}`, name: newProductName.value, items: [] };
    products.value.push(newProduct);
    filterProducts();
    creatingProduct.value = false;
    newProductName.value = '';
  }
};

const editProduct = (product) => {
  editingProduct.value = { ...product };
};

const saveProduct = () => {
  if (editingProduct.value) {
    saveState();
    const index = products.value.findIndex(product => product.id === editingProduct.value!.id);
    if (index !== -1) {
      products.value[index].name = editingProduct.value.name;
    }
    editingProduct.value = null;
    filterProducts();
  }
};

const cancelEdit = () => {
  editingProduct.value = null;
};

const cancelCreate = () => {
  creatingProduct.value = false;
  newProductName.value = '';
};

const deleteProduct = (productId) => {
  saveState();
  const index = products.value.findIndex(product => product.id === productId);
  if (index !== -1) {
    const itemsToUnclassify = products.value[index].items;
    items.value.push(...itemsToUnclassify);
    products.value.splice(index, 1);
    filterItems();
    filterProducts();
  }
};

const closeProductPane = () => emit('close-product-pane');

const toggleCollapse = (productId) => {
  if (collapsedProducts.value.has(productId)) {
    collapsedProducts.value.delete(productId);
  } else {
    collapsedProducts.value.add(productId);
  }
};

const collapseAllProducts = () => {
  products.value.forEach(product => {
    collapsedProducts.value.add(product.id);
  });
};

const expandAllProducts = () => {
  products.value.forEach(product => {
    collapsedProducts.value.delete(product.id);
  });
};

const isCollapsed = (productId) => {
  return collapsedProducts.value.has(productId);
};

const autoMapItemsToProduct = () => {
  saveState();
  products.value.forEach(product => {
    product.items = [];
    items.value.forEach(item => {
      if (item.name.toLowerCase().includes(product.name.toLowerCase())) {
        product.items.push(item);
      }
    });
  });
  filterItems();
};

const saveChanges = async () => {
  try {
    const productsToSave = products.value.map(product => ({
      id: product.id,
      name: product.name,
      items: product.items.map(item => ({ id: item.id }))
    }));

    const response = await fetch('/products/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      },
      body: JSON.stringify({ products: productsToSave })
    });

    const data = await response.json();
    if (data.status !== 'success') {
      throw new Error(data.message);
    }

    // Fetch updated products from the server
    await fetchProducts();

    alert('Changes saved successfully!');
  } catch (error) {
    console.error(error);
    alert('Failed to save changes.');
  }
};

watch(() => props.productPaneOpen, (newVal) => {
  if (newVal) {
    fetchItems();
    fetchProducts();
  }
});
</script>

<style>
.product-pane-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 50;
  padding: 1rem;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.dragging-items {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 100;
}

.create-product-modal,
.edit-product-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.input {
  padding: 5px;
  margin-bottom: 10px;
}

.text-red-500 {
  color: #f56565;
}

.bg-blue-500 {
  background-color: #4299e1;
}
</style>
